.table-container-dark {
    background-color: #333;
    border-radius: 5px;
    margin: 1%;
    padding: 0;
    -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.5); 
    text-align: center;
    color: white;
    font-size: 1.0em;
    overflow: scroll;
}
table.nhl-table {
    width: 100%;
}
table.nhl-table th {
    font-size: 0.8em;
    text-align: center;
    background-color: #333;
    color: #DDD;
    padding: 0 5px;
}
table.nhl-table td {
    font-size: 0.8em;
    text-align: center;
    color: black;
    padding: 0 5px;
}
table.nhl-table tr.partial-season > td {
    color: #777;
}

table.nhl-table tr:nth-child(even) td { background: #F1F1F1; }   
table.nhl-table tr:nth-child(odd) td { background: #FEFEFE; }  
