.bdd-loader {
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid rgb(241, 182, 69); /* Gold */
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 0.8s ease infinite;
}


  /* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


.lds-roller {
display: inline-block;
position: relative;
width: 80px;
height: 80px;
}
.lds-roller div {
animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
transform-origin: 40px 40px;
}
.lds-roller div:after {
content: " ";
display: block;
position: absolute;
width: 7px;
height: 7px;
border-radius: 50%;
/* background: #fff; */
margin: -4px 0 0 -4px;
}

.lds-roller.light div:after {
    background: #fff;
}
.lds-roller.dark div:after {
    background: #333;
}
.lds-roller.gold div:after {
    background: rgb(241, 182, 69);
}


.lds-roller div:nth-child(1) {
animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
top: 63px;
left: 63px;
}
.lds-roller div:nth-child(2) {
animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
top: 68px;
left: 56px;
}
.lds-roller div:nth-child(3) {
animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
top: 71px;
left: 48px;
}
.lds-roller div:nth-child(4) {
animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
top: 72px;
left: 40px;
}
.lds-roller div:nth-child(5) {
animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
top: 71px;
left: 32px;
}
.lds-roller div:nth-child(6) {
animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
top: 68px;
left: 24px;
}
.lds-roller div:nth-child(7) {
animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
top: 63px;
left: 17px;
}
.lds-roller div:nth-child(8) {
animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
top: 56px;
left: 12px;
}
@keyframes lds-roller {
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
}

/* Ellipsis */
.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
  
.lds-ellipsis.light div {
    background: #fff;
}
.lds-ellipsis.dark div {
    background: #333;
}
.lds-ellipsis.gold div {
    background: rgb(241, 182, 69);
}


/* Squares */
.lds-squares {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-squares div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #fff;
    animation: lds-squares 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .lds-squares div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
  }
  .lds-squares div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
  }
  .lds-squares div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
  }
  @keyframes lds-squares {
    0% {
      top: 8px;
      height: 64px;
    }
    50%, 100% {
      top: 24px;
      height: 32px;
    }
  }
  
.lds-squares.light div {
    background: #fff;
}
.lds-squares.dark div {
    background: #333;
}
.lds-squares.gold div {
    background: rgb(241, 182, 69);
}

/* Squares Small */
.lds-squares-sm {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 24px;
}
.lds-squares-sm div {
  display: inline-block;
  position: absolute;
  left: 4px;
  width: 8px;
  background: #fff;
  animation: lds-squares-sm 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-squares-sm div:nth-child(1) {
  left: 4px;
  animation-delay: -0.24s;
}
.lds-squares-sm div:nth-child(2) {
  left: 16px;
  animation-delay: -0.12s;
}
.lds-squares-sm div:nth-child(3) {
  left: 28px;
  animation-delay: 0;
}
@keyframes lds-squares-sm {
  0% {
    top: 0px;
    height: 24px;
  }
  50%, 100% {
    top: 6px;
    height: 14px;
  }
}

.lds-squares-sm.light div {
  background: #fff;
}
.lds-squares-sm.dark div {
  background: #333;
}
.lds-squares-sm.gold div {
  background: rgb(241, 182, 69);
}